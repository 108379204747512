.mall-products-filter {
    @apply .pr-8;
    .mall-property-group {
        @apply .mb-8;
        min-height: 105px; // Prevent jumping heights when the range filter is loaded
        &__name {
            @apply .border-b .border-smoke .pb-1 .mb-4;
            &--price {
                @apply .flex;
                & > div {
                    @apply .flex-1;
                }
            }
        }
        &__description {
            @apply .text-xs .text-grey-dark .mb-4 .-mt-2;
        }
    }
    .mall-property {
        @apply .mb-2;
        &:not(:last-child) .mall-filter.mall-filter--range {
            margin-bottom: 4rem;
        }
        &__label {
            @apply .text-sm .mb-2 .flex;
            & > div {
                @apply .flex-1;
            }
        }
        &__clear-filter {
            @apply .text-xs .font-normal .justify-end .text-right;
            a {
                @apply .text-grey .no-underline;
            }
        }
    }
    .mall-filter {
        @apply .flex .flex-wrap;
        &__option {
            @apply .p-2 .mr-2 .mb-2 .bg-smoke .cursor-pointer .text-center .text-sm;
            min-width: 40px;
            &--selected:not(.mall-label--checkbox) {
                @apply .bg-primary .text-white;
            }
        }
    }
    .noUi {
        &-connect {
            @apply .bg-primary;
        }
        &-horizontal {
            height: 10px;
            .noUi-handle {
                width: 25px;
                height: 20px;
                right: -13px !important;
            }
            .noUi-handle:before {
                height: 10px;
                left: 10px;
                top: 4px;
            }
            .noUi-handle:after {
                height: 10px;
                left: 12px;
                top: 4px;
            }
        }
        &-target {
            font-size: .7rem;
            margin-bottom: 0;
        }
        &-marker-horizontal {
            width: 1px;
            height: 4px;
            &.noUi-marker-large {
                height: 8px;
            }
        }
        &-value {
            top: 15px;
        }
        &-pips-horizontal {
            height: 20px;
            padding: 10px 0 0;
        }
    }
}